import { ROUTE } from "routes";

type RouteValue = (typeof ROUTE)[keyof typeof ROUTE];
type RouteGroups = {
  [key in RouteValue]?: RouteValue[];
};

export const getActiveMenuKey = (path: string): RouteValue | string => {
  const routeGroups: RouteGroups = {
    [ROUTE.CANDIDATES]: [ROUTE.CANDIDATES, ROUTE.CANDIDATE, ROUTE.RECOMMENDED],
    [ROUTE.VACANCIES]: [ROUTE.VACANCIES, ROUTE.VACANCY],
    [ROUTE.REPLIES]: [ROUTE.REPLIES, ROUTE.REPLY, ROUTE.CLAIM],
    [ROUTE.ADAPTATION_LIST]: [ROUTE.ADAPTATION_LIST, ROUTE.ADAPTATION],
  };

  const foundEntry = Object.entries(routeGroups).find(([_, routes]) =>
    routes?.some((route) => path.startsWith(route))
  );

  return foundEntry ? foundEntry[0] : path;
};
