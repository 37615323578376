import React, { useEffect } from "react";
import { useParams } from "react-router-dom";
import { useSelector } from "react-redux";

import { ContentLayout } from "layouts/ContentLayout";
import { Candidate } from "components/redezign/Candidate";
import { CandidateActions } from "components/redezign/Candidate/components/CandidateActions";
import { useAppDispatch } from "services/store/store";
import { RouteParams } from "common/commonTypes/types";
import { selectCandidate } from "services/store/modules/candidate/selectors";
import {
  fetchCandidateById,
  getCandidateHistory,
} from "services/store/modules/candidate/actions";
import { getCompanySettings } from "services/store/modules/company";

export const CandidatePage: React.FC = () => {
  const dispatch = useAppDispatch();

  const { id } = useParams<RouteParams>();

  const { data: candidate, loading } = useSelector(selectCandidate);

  useEffect(() => {
    Promise.all([
      dispatch(fetchCandidateById(id)),
      dispatch(getCandidateHistory(id)),
      dispatch(getCompanySettings()),
    ]);
  }, [dispatch, id]);

  return (
    <ContentLayout
      centralColumn={{
        content: <Candidate candidate={candidate} loading={loading} />,
        width: 18,
      }}
      rightColumn={{
        width: 6,
        content: candidate ? (
          <CandidateActions candidateId={candidate.id} candidate={candidate} />
        ) : null,
      }}
    />
  );
};
