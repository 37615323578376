import React, { useMemo, useState } from "react";
import { Layout, Menu } from "antd";
import { useHistory, useLocation } from "react-router-dom";
import { MenuClickEventHandler } from "rc-menu/lib/interface";
import {
  UnorderedListOutlined,
  CheckOutlined,
  CheckCircleOutlined,
  BookOutlined,
  LineChartOutlined,
  Chevron,
} from "components/UI/icons";
import { useSelector } from "react-redux";
import { authInfo, role } from "services/store/modules/auth";
import { UserRole } from "graphql/types/types";
import { IndicatorServiceWorkers } from "./components/IndicatorServiceWorkers";
import { Button } from "components/UI/Button";
import { ROUTE } from "routes";
import { getActiveMenuKey } from "./helpers";
import { hasModerationAccess } from "common/helpers/role";
import { Logo } from "components/Logo";
import { useChangeTitle } from "common/hooks/header/useChangeTitle";
import { isProduction } from "common/utils/environments";

import "./styles.scss";
import { Plus } from "../../UI/icons/Pluse";

const { Sider } = Layout;

interface SidebarProps {
  isCollapsed: boolean;
  onCollapse(): void;
}

type MenuItemsParams = {
  company: number;
  userRole?: UserRole;
  userDepartmentName?: string;
};

const getMenuItems = ({
  company,
  userRole,
  userDepartmentName,
}: MenuItemsParams) => [
  {
    label: "Вакансии",
    path: "/vacancies",
    icon: <UnorderedListOutlined />,
    isVisible: true,
  },
  {
    label: "Отклики",
    path: "/replies",
    icon: <CheckOutlined />,
    isVisible: true,
  },
  {
    label: "Заявки",
    path: "/claims",
    icon: <CheckCircleOutlined />,
    isVisible: company === 1 && userRole !== UserRole.Moderator,
  },
  {
    label: "Контроль",
    path: "/control",
    icon: <LineChartOutlined />,
    isVisible: userRole && hasModerationAccess(userRole),
  },
  {
    label: "База знаний",
    path: "/knowledge-base",
    icon: <BookOutlined />,
    isVisible: userDepartmentName === "КЦ",
  },
];

const betaMenuItems = [
  {
    label: "Проверка вакансий",
    path: ROUTE.VACANCIES,
    icon: <UnorderedListOutlined />,
    isVisible: true,
  },
  {
    label: "Общая база кандидатов",
    path: ROUTE.CANDIDATES,
    icon: <BookOutlined />,
    isVisible: true,
  },
  {
    label: "Новые отклики",
    path: ROUTE.REPLIES_NEW,
    icon: <CheckOutlined />,
    isVisible: true,
  },
  {
    label: "Работа с кандидатом",
    path: ROUTE.ADAPTATION_LIST,
    icon: <CheckOutlined />,
    isVisible: !isProduction(),
  },
  {
    label: "Создание кандидата",
    path: ROUTE.CREATE_CANDIDATE,
    icon: <Plus />,
    isVisible: true,
  },
];

export const Sidebar: React.FC<SidebarProps> = ({
  isCollapsed,
  onCollapse,
}) => {
  const location = useLocation();
  const history = useHistory();

  const { pathname } = location;

  const userRole = useSelector(role);
  const userDepartmentName = useSelector(authInfo)?.department?.name;
  const company = useSelector(authInfo)?.company?.id || 0;

  const [selectedKey, setSelectedKey] = useState(getActiveMenuKey(pathname));

  const menuItems = useMemo(
    () => getMenuItems({ company, userRole, userDepartmentName }),
    [company, userRole, userDepartmentName]
  );

  const title = useMemo(
    () =>
      [...menuItems, ...betaMenuItems].find((item) => item.path === pathname)
        ?.label || null,
    [menuItems, pathname]
  );

  useChangeTitle({ title });

  const handleClick: MenuClickEventHandler = ({ key }) => {
    setSelectedKey(getActiveMenuKey(key));
    history.push({ pathname: key });
  };

  return (
    <Sider
      className="sidebar"
      trigger={null}
      width={230}
      theme={"light"}
      collapsible
      collapsed={isCollapsed}
      onCollapse={onCollapse}
    >
      <Logo isCollapsed={isCollapsed} />

      <Menu selectedKeys={[selectedKey]} mode={"inline"} onClick={handleClick}>
        {menuItems.map(({ path, label, icon, isVisible }) => {
          if (!isVisible) {
            return null;
          }

          return (
            <Menu.Item
              key={path}
              icon={<span className="icon">{icon}</span>}
              title={label}
            >
              {label}
            </Menu.Item>
          );
        })}

        <hr className="sidebar-hr" />

        {betaMenuItems.map(({ path, label, icon, isVisible }) => {
          if (!isVisible) {
            return null;
          }

          return (
            <Menu.Item
              key={path}
              icon={<span className="icon">{icon}</span>}
              title={label}
            >
              {label}
            </Menu.Item>
          );
        })}
      </Menu>

      <Button
        icon={
          <Chevron
            style={{
              transform: isCollapsed ? "rotate(180deg)" : "rotate(0deg)",
            }}
          />
        }
        variant="primary"
        size="small"
        onClick={onCollapse}
      />

      <IndicatorServiceWorkers isCollapsed={isCollapsed} />
    </Sider>
  );
};
